<template>
  <div>
    <v-alert
      :prominent="!$vuetify.breakpoint.xs"
      class="ma-0 rounded-t-0"
      icon="mdi-file-document-check-outline"
      color="primary"
      text
    >
      Du hast unsere Benutzungsrichtlinien noch nicht akzeptiert oder sie wurden
      seitdem angepasst. Bitte lies die Richtlinien durch und akzeptiere sie.
    </v-alert>
    <v-card :class="`v-alert--text primary--text rounded-t-0`" flat tile>
      <v-divider />

      <v-card-actions>
        <template>
          <v-dialog v-model="dialog" width="600" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" text small block color="primary"
                >lesen & akzeptieren</v-btn
              >
            </template>

            <v-card>
              <v-system-bar
                window
                :color="policyAccepted ? 'success' : 'danger'"
              >
                Benutzungsrichtlinien<v-spacer></v-spacer
                ><v-btn @click="dialog = false" icon small
                  ><v-icon>mdi-close</v-icon></v-btn
                ></v-system-bar
              >
              <div>
                <DocumentItemLoader :id="201091" />
                <v-divider></v-divider>
              </div>
              <v-card-text>
                <Markdown :value="policy" class="mt-4" />

                <v-alert
                  outlined
                  :color="policyAccepted ? 'success' : 'danger'"
                >
                  <v-checkbox
                    v-model="policyAccepted"
                    color="primary"
                    label="ich habe die Richtlinien gelesen und akzeptiere diese"
                  ></v-checkbox>
                </v-alert>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  block
                  text
                  @click="save"
                  :disabled="!policyAccepted"
                  >speichern & schliessen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </template>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import DocumentItemLoader from "@/components/DocumentItemLoader.vue";
import policy from "raw-loader!@/assets/policy.md";

export default defineComponent({
  name: "Policy",
  components: { DocumentItemLoader },
  data() {
    return {
      policy: policy,
      policyAccepted: false,
      dialog: false,
      saving: false,
    };
  },
  computed: {},
  methods: {
    async fetchData() {
      if (
        !(
          await this.apiList({
            resource: "account/policy",
          })
        ).policyAccepted
      ) {
        this.$emit("loaded");
      }
    },
    async save() {
      this.saving = true;
      await this.apiPost({
        resource: "account/policy",
        data: { policyAccepted: true },
      });
      this.saving = false;
      this.dialog = false;
      this.$emit("close");
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
