export default [
  {
    name: "Benutzerrichlinien",
    icon: "mdi-scale-balance",
    component: () => import("./Policy.vue"),
    shown: true,
    forced: true,
    ready: false,
  },
  {
    name: "Geburtstag",
    icon: "mdi-cake",
    component: () => import("./Birthday.vue"),
    shown: true,
    forced: true,
    ready: false,
  },
  {
    name: "Kontrollen",
    icon: "mdi-check",
    component: () => import("./AttendanceCheck.vue"),
    shown: true,
    forced: true,
    ready: false,
    role: ["teacher"],
    updateOnFocus: true,
    autoUpdateInterval: 20,
  },
  {
    name: "Absenzen",
    icon: "mdi-bed",
    component: () => import("./Absences.vue"),
    shown: true,
    forced: true,
    ready: false,
    role: ["student"],
    autoUpdateInterval: 20,
  },
  {
    name: "Absenzenabschluss",
    icon: "mdi-bed",
    component: () => import("./AbsenceBalance.vue"),
    shown: true,
    forced: true,
    ready: false,
    role: ["classTeacher"],
    autoUpdateInterval: 60,
  },
  {
    name: "Absenzenwesen",
    icon: "mdi-bed",
    component: () => import("./Justifications.vue"),
    shown: true,
    forced: true,
    ready: false,
    role: ["classTeacher"],
    autoUpdateInterval: 60,
  },
  {
    name: "myGymer-Angebote",
    icon: "mdi-star",
    component: () => import("./OptionalStudent.vue"),
    link: { name: "StudentOptional" },
    shown: true,
    forced: true,
    ready: false,
    role: ["student"],
    autoUpdateInterval: 110,
  },
  {
    name: "myGymer-Angebote",
    icon: "mdi-star",
    component: () => import("./OptionalTeacher.vue"),
    link: { name: "OptionalSchoolClass" },
    shown: true,
    forced: true,
    ready: false,
    role: ["classTeacher"],
    autoUpdateInterval: 110,
  },
  {
    name: "Maturaarbeit",
    icon: "mdi-puzzle",
    component: () => import("./ThesisStudent.vue"),
    link: { name: "Thesis" },
    shown: true,
    forced: true,
    ready: false,
    role: ["student"],
  },
  {
    name: "ReleaseNotes",
    icon: "mdi-checkbox-marked-circle-plus-outline",
    component: () => import("./ReleaseNotes.vue"),
    link: { name: "ReleaseNotes" },
    shown: true,
    ready: false,
    forced: true,
  },
  {
    name: "Unterricht",
    icon: "mdi-calendar-account",
    component: () => import("./Register.vue"),
    link: { name: "MyRegister" },
    shown: true,
    forced: false,
    ready: true,
    updateOnFocus: true,
    autoUpdateInterval: 2,
  },
  {
    name: "Hausaufgaben",
    icon: "mdi-pen",
    component: () => import("./Homework.vue"),
    shown: true,
    ready: false,
    role: ["student"],
    updateOnFocus: true,
    autoUpdateInterval: 6,
  },
  {
    name: "Proben",
    icon: "mdi-certificate",
    component: () => import("./Exams.vue"),
    link: { name: "MyExams" },
    shown: true,
    ready: false,
    role: ["student"],
    updateOnFocus: true,
    autoUpdateInterval: 6,
  },
  {
    name: "Termine 3 Tage",
    link: "/events",
    icon: "mdi-calendar",
    component: () => import("./Events.vue"),
    shown: true,
    ready: false,
    autoUpdateInterval: 120,
  },
  {
    name: "Wetter",
    icon: "mdi-weather-cloudy",
    component: () => import("./Weather.vue"),
    href: "https://wetter.gymkirchenfeld.ch",
    shown: true,
    ready: false,
    updateOnFocus: true,
    autoUpdateInterval: 15,
  },
  {
    name: "Maturaarbeiten",
    icon: "mdi-puzzle",
    component: () => import("./ThesisTeacher.vue"),
    link: { name: "Thesis" },
    shown: true,
    ready: false,
    role: ["teacher"],
  },
  {
    name: "Notiz",
    icon: "mdi-note",
    component: () => import("./Note.vue"),
    shown: true,
    ready: false,
  },
  {
    name: "Links",
    icon: "mdi-link",
    component: () => import("./Links.vue"),
    shown: true,
    ready: true,
  },
  {
    name: "Bilder",
    icon: "mdi-image",
    component: () => import("./Gallery.vue"),
    link: { name: "Gallery" },
    shown: true,
    ready: false,
  },
  {
    name: "Druckguthaben",
    icon: "mdi-printer",
    component: () => import("./Print.vue"),
    link: { name: "StudentPrint" },
    shown: true,
    ready: false,
    role: ["student"],
    autoUpdateInterval: 50,
  },
  {
    name: "Anzeigetafel",
    icon: "mdi-television",
    component: () => import("./Screen.vue"),
    link: { name: "Screen" },
    shown: true,
    ready: false,
    updateOnFocus: true,
    autoUpdateInterval: 60,
  },
];
