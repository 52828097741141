<template>
  <v-bottom-sheet width="500" v-model="sheet">
    <EventSheet :value="item" v-if="item" @close="sheet = false"
  /></v-bottom-sheet>
</template>
<script>
import { defineComponent } from "vue";
import EventSheet from "common/components/EventSheet.vue";

export default defineComponent({
  components: { EventSheet },
  props: ["id"],
  data() {
    return {
      loading: false,
      item: {},
      sheet: true,
    };
  },
  watch: {
    id() {
      this.fetchData();
    },
    sheet() {
      if (!this.sheet) {
        this.$router.push({ name: "EventList" });
      }
    },
  },
  methods: {
    async fetchData() {
      this.item = await this.apiGet({
        resource: "course/event",
        id: this.id,
      });
    },
  },
  created() {
    this.fetchData();
  },
});
</script>
